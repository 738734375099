var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-create", {
        ref: "formCreate",
        attrs: { option: _vm.option, rule: _vm.rules, value: _vm.value },
        on: {
          "update:value": function ($event) {
            _vm.value = $event
          },
          change: _vm.handleFieldChange,
        },
        model: {
          value: _vm.fApi,
          callback: function ($$v) {
            _vm.fApi = $$v
          },
          expression: "fApi",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }