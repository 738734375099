<template>
  <div class="modules">
      <el-button type="primary" @click="addModule" v-if="!disabled">新增模块</el-button>
      <el-button type="danger" @click="deleteModule(-1)" v-if="this.modules.length > 0">删除模块</el-button>
      <el-divider></el-divider>
    <div v-for="(item, index) in modules" :key="modulesKey+index">
      <el-row class="modules-title">
        <span>模块{{index+1}}</span>
        <!-- <el-button class="add-btn" type="text" icon="el-icon-circle-plus-outline" :style="style" @click="addModule" v-if="index==0">添加</el-button> -->
        <el-button class="delete-btn" type="text" icon="el-icon-delete" :style="style" @click="deleteModule(index)">删除</el-button>
      </el-row>
      <el-form>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="模块名称" label-width="80px">
              <el-input @change="emitChange" v-model="item.modelName" :disabled="disabled" clearable placeholder="模块名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="步骤logo" label-width="80px">
              <Upload
                :limit="1"
                :value="modules[index].modelIcon"
                :disabled="disabled"
                fileMsg="只能上传jpg/png/jpeg文件"
                accept=".png,.jpg,.jpeg"
                @onGetFileList="(val)=>getFileList(val,index)"
              ></Upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="margin-bottom-10" v-if="!disabled">
        <el-button type="primary" @click="addControls(index)">添加</el-button>
        <el-button type="danger" @click="deleteControls(index)">删除</el-button>
      </el-row>
      <vxe-table class="margin-bottom-10" :data="item.controls" :ref="'xTable'+index" @checkbox-all="({records}) => selectEvent(records, index)" @checkbox-change="({records}) => selectEvent(records, index)" :edit-config="{trigger: 'click', mode: 'cell', activeMethod: activeCellMethod}">
        <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
        <vxe-table-column title="选择控件" field="controlType" :edit-render="{name: '$select', options: typeOptions, events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
        <vxe-table-column title="控件名称" field="controlName" :edit-render="{name: 'input', props: {type: 'text'}, events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
        <vxe-table-column title="扩展属性" min-width="100">
          <template v-slot="{rowIndex, row}">
            <el-button type="text" @click="addConfig(index, rowIndex, row)">点击配置</el-button>
          </template>
        </vxe-table-column>
        <vxe-table-column title="数据字典" field="dictCode" :edit-render="{name: '$input', events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
        <vxe-table-column  title="是否必填" field="requireType" :edit-render="{name: '$select', options: options, events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
        <vxe-table-column title="排序" field="sort" :edit-render="{name: '$input', props: {type: 'number'}, events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
        <vxe-table-column title="字段名" field="fieldName" :edit-render="{name: '$input', events: {change: (row)=>sexChangeEvent(row,index)}}" min-width="100"></vxe-table-column>
      </vxe-table>
    </div>

    <!-- 添加控件弹窗 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <Controls @getRowConfig="getRowConfig" :disabled="disabled" :row="row"></Controls>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" v-if="!disabled">提交</el-button>
        <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';
import Upload from '../../../../../../../components/form/components/form_upload.vue';
import Modal from '../../../../../../../components/modal';
import Controls from './controls.vue';

export default {
  components: {
    Upload,
    Modal,
    Controls,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!this.disabled) {
          this.modules = val || [];
        } else {
          this.modules = val || [];
        }
        const { modules } = this;
        if (modules && modules.length > 0) {
          modules.forEach((v) => {
            if (v.controls && v.controls.length > 0) {
              v.controls.forEach((item) => {
                const m = item;
                this.typeOptions.forEach((o) => {
                  if (o.dictCode === m.controlType) {
                    m.formItem = o.children || [];
                  }
                });
              });
            }
          });
        }
        this.modules = modules;
      },
    },
  },
  data() {
    return {
      modulesKey: 0, // 为了模块刷新
      modules: [], // 模块列表
      controls: [], // 控件列表
      selectList: {}, // 选择的列表
      modalConfig: {
        title: '控件配置',
        visible: false,
        type: 'Modal',
        width: '600px',
      }, // 添加控件弹窗
      index: 0, // 操作模块的索引
      row: {}, // 点击配置那一行的数据
      rowIndex: 0, // 点击配置那一行的索引
      config: {}, // 控件配置填写的数据
      typeOptions: [], // 控件类型
      options: [], // 是否必填选项
    };
  },
  computed: {
    style() {
      return { display: this.disabled ? 'none' : 'inline-block' };
    },
  },
  async created() {
    await this.getControlType();
    this.getDoNot();
  },
  methods: {
    // 获取是选择控件数据字典
    async getControlType() {
      const res = await request.get('/mdm/mdmdictdata/dictTree', { dictTypeCode: 'SFAcontrolType' });
      if (res.result && res.result.length > 0) {
        this.typeOptions = res.result.map((item) => ({ ...item, label: item.dictValue, value: item.dictCode }));
      }
    },
    // 获取是否必填数据字典
    async getDoNot() {
      const res = await request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'doNot' });
      if (res.result && res.result.length > 0) {
        this.options = res.result.map((item) => ({ ...item, label: item.dictValue, value: item.dictCode }));
      }
    },
    // 点击添加模块
    addModule() {
      this.modules.push({ controls: [] });
      this.modulesKey += 1;
    },
    // 删除模块
    deleteModule(index) {
      if (index === -1) {
        this.$confirm('确定删除所有模块吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.modules = [];
          this.emitChange();
        }).catch(() => {
          // 取消删除
        });
      } else {
        this.modules.splice(index, 1);
        this.emitChange();
      }
    },
    // 点击配置按钮
    addConfig(index, rowIndex, row) {
      const rowData = row;
      if (!row.controlType) {
        this.$message.error('请选择控件');
      } else if (!row.formItem || row.formItem.length <= 0) {
        this.$message.error('无拓展属性');
      } else {
        this.row = rowData;
        this.index = index;
        this.rowIndex = rowIndex;
        this.modalConfig.visible = true;
      }
    },
    // 获取配置数据
    getRowConfig(val) {
      this.config = val;
    },
    // 获取模块的步骤logo
    getFileList(val, index) {
      this.modules[index].modelIcon = val;
    },
    // 选择变化
    selectEvent(records, index) {
      this.selectList[index] = records;
    },
    // 设置为是否可输入
    activeCellMethod({ columnIndex }) {
      if (this.disabled) {
        return false;
      }
      return true;
    },
    sexChangeEvent(row, index) {
      this.$refs[`xTable${index}`][0].clearActived();// 清除单元格激活状态
      this.$refs[`xTable${index}`][0].setActiveCell(row.row, row.column.property);// 设置单元格为激活状态
      if (row.columnIndex === 1) {
        const tIndex = this.typeOptions.findIndex((val) => val.value === row.row.controlType);
        this.modules[index].controls[row.rowIndex].formItem = this.typeOptions[tIndex].children || [];
      }
      this.emitChange();
    },
    /** @desc 顶部添加控件按钮 */
    addControls(index) {
      this.modules[index].controls.push({ config: {} });
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
    // 获取控件数据
    getSelectRow(e) {
      this.selectRow = e;
    },
    // 控件弹窗提交按钮
    submit() {
      this.modules[this.index].controls[this.rowIndex].config = this.config;
      this.modules[this.index].controls[this.rowIndex].extendParam = this.config ? JSON.stringify(this.config) : null;
      this.modalConfig.visible = false;
      this.emitChange();
    },
    /** @desc 顶部删除控件按钮 */
    deleteControls(index) {
      // 删除选中的数据
      if (this.selectList[index] && this.selectList[index].length > 0) {
        this.selectList[index].map((item) => {
          const cIndex = this.modules[index].controls.findIndex((val) => val._XID === item._XID);
          if (cIndex > -1) {
            this.modules[index].controls.splice(cIndex, 1);
          }
          return item;
        });
        this.selectList[index] = [];
      } else {
        this.$message.error('请选择数据');
      }
      this.emitChange();
    },
    emitChange() {
      const modules = this.modules.map((item) => ({ ...item, sfaVisitStepFromControlReqVoList: item.controls || [] }));
      this.$emit('input', modules || []);
    },
  },
};
</script>
<style lang="less" scoped>
  .modules{
    padding-left: 40px;
  }
  .modules-title{
    margin-bottom: 6px;
    span{
      font-size: 16px;
      color: #606266;
    }
    button{
      padding-left: 8px;
    }
    .delete-btn{
      color: red;
    }
  }
  .margin-bottom-6{
    margin-bottom: 6px;
  }
  .margin-bottom-10{
    margin-bottom: 10px;
  }
</style>
