<template>
  <div>
    <form-create
      ref="formCreate"
      v-model="fApi"
      :option="option"
      :rule="rules"
      :value.sync="value"
      @change="handleFieldChange"
    ></form-create>
  </div>
</template>

<script>

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.formItem) {
          if (val.config) {
            this.value = val.config || {};
          }
          if (val.extendParam) {
            this.value = JSON.parse(val.extendParam);
          }
          this.rules = val.formItem.map((item) => ({
            ...item,
            type: 'input',
            title: item.dictDesc,
            field: item.dictCode,
            value: this.value[item.dictCode] || null,
            props: {
              type: 'text',
              clearable: true, // 是否显示清空按钮
              placeholder: item.dictValue,
              disabled: this.disabled,
            },
          }));
        }
      },
    },
  },
  data() {
    return {
      // 实例对象
      fApi: {},
      // 表单数据
      value: {},
      // 表单生成规则
      rules: [],
      option: {
        resetBtn: false,
        submitBtn: false,
        global: {
          // 设置所有组件
          '*': {
            props: {
              size: 'small',
            },
          },
        },
      },
    };
  },
  methods: {
    handleFieldChange(field, value) {
      this.value[field] = value;
      this.$emit('getRowConfig', this.value);
    },
  },
};
</script>
