<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import FormControls from './components/form_controls.vue';

formCreate.component('sfaVisitManageFormControls', FormControls);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      hasclientSubclass: true, // 是否精确到客户细类
    };
  },
  async created() {
    // 查询全局参数获取是否精确到客户细类
    const params = {
      parameterCode: 'sfa_visit_step_config_client_subclass_range',
    };
    const requestUrl = '/mdm/mdmparametermanage/query';
    request.post(requestUrl, params).then((res) => {
      if (res.success) {
        this.hasclientSubclass = res.result.parameterValue === 'Y';
        console.log(res.result.parameterValue);
      }
    });
    // const rule = await this.getBeforFormRule('sfaCenter_visitManage_visitStepForm_add');
    const rule = await this.getFormRule('sfaCenter_visitManage_visitStepForm_add');
    const _this = this;
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'stepCode') { // 让步骤编码显示code
        v.restful = '/mdm/mdmdictdata/list';
        v.restfulParams = {
          dictTypeCode: 'sfa_step_code',
        };
        v.optionsKey = {
          label: 'dictCode',
          value: 'dictCode',
        };
      }

      if (v.field === 'visitType') { // 拜访类型
        v.on = {
          ...v.on,
          change: (val) => {
            if (val === 'UNFAMILIAR_VISIT') { // 如果拜访类型是陌拜则隐藏客户类型 和客户细类
              _this.hiddenFields(true, ['customerType', 'clientSubclassTerminal', 'clientSubclassDealer']);
            } else {
              _this.hiddenFields(false, 'customerType');
            }
          },
        };
      }
      if (v.field === 'customerType') { // 根据客户类型查找细分客户
      //  默认隐藏终端商细分客户
        _this.hiddenFields(true, ['clientSubclassTerminal']);
        _this.hiddenFields(true, ['clientSubclassDealer']);
        v.on = {
          ...v.on,
          change(val) {
            console.log(_this.hasclientSubclass);
            if (val === 'dealer' && _this.hasclientSubclass) { // 经销商
              _this.hiddenFields(true, ['clientSubclassTerminal']);
              _this.hiddenFields(false, ['clientSubclassDealer']);
            } else if (val === 'terminal' && _this.hasclientSubclass) { // 终端
              _this.hiddenFields(true, ['clientSubclassDealer']);
              _this.hiddenFields(false, ['clientSubclassTerminal']);
            }
          },
        };
      }
      if (v.field === 'timeType') {
        v.props = {
          ...v.props,
          clearable: false,
        };
        v.on = {
          change(val) {
            if (val === 'long_time') {
              _this.hiddenFields(true, ['effectiveTime']);
            } else {
              _this.hiddenFields(false, ['effectiveTime']);
            }
          },
        };
        if (!v.value || v.value === 'long_time') {
          this.hiddenFields(true, ['effectiveTime']);
        } else {
          _this.hiddenFields(false, ['effectiveTime']);
        }
      } else if (v.field === 'effectiveTime') {
        v.props = {
          ...v.props,
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        };
        v.type = 'hidden';
      } else if (v.field === 'icon') {
        v.props = {
          ...v.props,
          limit: 1,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
        };
      } else if (v.field === 'stepFromModelReqVoList') {
        Object.assign(v, {}, { props: { disabled: this.formConfig.disabled } });
      }
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          allowRemove: false, // 查看时不允许移除图片
          disabled: true,
        };
        this.buttons.submit = false;
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      request
        .get('/sfa/sfaVisitStepFromController/queryStepFromById', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            if (!result.timeType || result.timeType === 'long_time') {
              this.hiddenFields(true, ['effectiveTime']);
            } else {
              if (result.startTime && result.endTime) {
                result.effectiveTime = [];
                result.effectiveTime.push(result.startTime);
                result.effectiveTime.push(result.endTime);
              }
              this.hiddenFields(false, ['effectiveTime']);
            }
            // 根据客户类型给细分客户赋值
            if (result.customerType === 'dealer' && this.hasclientSubclass) {
              this.hiddenFields(true, ['clientSubclassTerminal']);
              this.hiddenFields(false, ['clientSubclassDealer']);
              result.clientSubclassDealer = result.clientSubclass;
            } else if (result.customerType === 'terminal' && this.hasclientSubclass) {
              this.hiddenFields(true, ['clientSubclassDealer']);
              this.hiddenFields(false, ['clientSubclassTerminal']);
              result.clientSubclassTerminal = result.clientSubclass;
            }
            // 如果是陌拜则不显示客户类型
            if (result.visitType === 'UNFAMILIAR_VISIT') {
              this.hiddenFields(true, ['customerType', 'clientSubclassTerminal', 'clientSubclassDealer']);
            }
            result.locateType = result.locateType === 'Y';
            if (result) this.row = result;
            this.setValue(result);
            if (result.stepFromModelRespVoList && result.stepFromModelRespVoList.length > 0) {
              const stepFromModelReqVoList = result.stepFromModelRespVoList.map((item) => ({ ...item, controls: item.sfaVisitStepFromControlRespVoList }));
              this.setValue({
                stepFromModelReqVoList,
              });
            }
          }
        });
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = formData;
      param.locateType = param.locateType ? 'Y' : 'N';
      // 更改细分客户
      if (param.customerType === 'dealer') {
        param.clientSubclass = param.clientSubclassDealer;
      } else if (param.customerType === 'terminal') {
        param.clientSubclass = param.clientSubclassTerminal;
      } else {
        param.clientSubclass = null;
      }
      if (param) {
        const url = '/sfa/sfaVisitStepFromController/saveOrUpdateStepFrom';
        let params = { ...param };
        params.startTime = param.effectiveTime ? param.effectiveTime[0] : null;
        params.endTime = param.effectiveTime ? param.effectiveTime[1] : null;
        // 细分客户
        if (params.customerType === 'dealer') {
          params.clientSubclass = params.clientSubclassDealer;
        } else if (params.customerType === 'terminal') {
          params.clientSubclass = params.clientSubclassTerminal;
        } else {
          params.clientSubclass = null;
        }
        // 拜访步骤模块参数
        if (params.stepFromModelReqVoList && params.stepFromModelReqVoList.length > 0) {
          params.stepFromModelReqVoList.map((v) => {
            const item = v;
            delete item.sfaVisitStepFromControlRespVoList;
            if (item.controls && item.controls.length > 0) {
              item.controls.map((m) => {
                const items = m;
                delete items.config;
                delete items.formItem;
                return items;
              });
            }
            item.sfaVisitStepFromControlReqVoList = item.controls;
            delete item.controls;
            return item;
          });
        }
        if (this.formConfig.type === 'edit') {
          // url = '/sfa/sfaVisitRoleConfigController/update';
          params = Object.assign(this.row, param);
          delete params.stepFromModelRespVoList;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-form-item__content .el-date-editor {
    width: 100%;
  }
  /deep/ .el-date-editor .el-range-separator {
      width: 10%;
    }

</style>
