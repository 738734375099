var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modules" },
    [
      !_vm.disabled
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addModule } },
            [_vm._v("新增模块")]
          )
        : _vm._e(),
      this.modules.length > 0
        ? _c(
            "el-button",
            {
              attrs: { type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.deleteModule(-1)
                },
              },
            },
            [_vm._v("删除模块")]
          )
        : _vm._e(),
      _c("el-divider"),
      _vm._l(_vm.modules, function (item, index) {
        return _c(
          "div",
          { key: _vm.modulesKey + index },
          [
            _c(
              "el-row",
              { staticClass: "modules-title" },
              [
                _c("span", [_vm._v("模块" + _vm._s(index + 1))]),
                _c(
                  "el-button",
                  {
                    staticClass: "delete-btn",
                    style: _vm.style,
                    attrs: { type: "text", icon: "el-icon-delete" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteModule(index)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            ),
            _c(
              "el-form",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "模块名称", "label-width": "80px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.disabled,
                                clearable: "",
                                placeholder: "模块名称",
                              },
                              on: { change: _vm.emitChange },
                              model: {
                                value: item.modelName,
                                callback: function ($$v) {
                                  _vm.$set(item, "modelName", $$v)
                                },
                                expression: "item.modelName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "步骤logo", "label-width": "80px" },
                          },
                          [
                            _c("Upload", {
                              attrs: {
                                limit: 1,
                                value: _vm.modules[index].modelIcon,
                                disabled: _vm.disabled,
                                fileMsg: "只能上传jpg/png/jpeg文件",
                                accept: ".png,.jpg,.jpeg",
                              },
                              on: {
                                onGetFileList: function (val) {
                                  return _vm.getFileList(val, index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.disabled
              ? _c(
                  "el-row",
                  { staticClass: "margin-bottom-10" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.addControls(index)
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteControls(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "vxe-table",
              {
                ref: "xTable" + index,
                refInFor: true,
                staticClass: "margin-bottom-10",
                attrs: {
                  data: item.controls,
                  "edit-config": {
                    trigger: "click",
                    mode: "cell",
                    activeMethod: _vm.activeCellMethod,
                  },
                },
                on: {
                  "checkbox-all": function (ref) {
                    var records = ref.records

                    return _vm.selectEvent(records, index)
                  },
                  "checkbox-change": function (ref) {
                    var records = ref.records

                    return _vm.selectEvent(records, index)
                  },
                },
              },
              [
                !_vm.disabled
                  ? _c("vxe-table-column", {
                      attrs: { type: "checkbox", width: "50" },
                    })
                  : _vm._e(),
                _c("vxe-table-column", {
                  attrs: {
                    title: "选择控件",
                    field: "controlType",
                    "edit-render": {
                      name: "$select",
                      options: _vm.typeOptions,
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "控件名称",
                    field: "controlName",
                    "edit-render": {
                      name: "input",
                      props: { type: "text" },
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: { title: "扩展属性", "min-width": "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var rowIndex = ref.rowIndex
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addConfig(index, rowIndex, row)
                                  },
                                },
                              },
                              [_vm._v("点击配置")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "数据字典",
                    field: "dictCode",
                    "edit-render": {
                      name: "$input",
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "是否必填",
                    field: "requireType",
                    "edit-render": {
                      name: "$select",
                      options: _vm.options,
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "排序",
                    field: "sort",
                    "edit-render": {
                      name: "$input",
                      props: { type: "number" },
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "字段名",
                    field: "fieldName",
                    "edit-render": {
                      name: "$input",
                      events: {
                        change: function (row) {
                          return _vm.sexChangeEvent(row, index)
                        },
                      },
                    },
                    "min-width": "100",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c("Controls", {
            attrs: { disabled: _vm.disabled, row: _vm.row },
            on: { getRowConfig: _vm.getRowConfig },
          }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }